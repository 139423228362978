import { ApiResponse, create } from 'apisauce';
import { getTokenFromStorage } from '../utils/localStorage';
import { ROUTES } from '../constants';
import {
  ClaimsListParams, CountriesListParams,

} from '../types';
import { AllClaimsReportPdfResponse } from './api/api.types';
import { checkResponse } from './api/api.helpers';

const token = getTokenFromStorage();

export const pdfService = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 300000,
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
  },
});

export const getFileAllClaimsReportPdf = async (
  params: ClaimsListParams,
): Promise<AllClaimsReportPdfResponse> => {
  try {
    const response: ApiResponse<AllClaimsReportPdfResponse> = await pdfService.get(
      ROUTES.REPORTS.ALL_CLAIMS_PDF, params,
    );
    checkResponse(response);
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error('Bad data');
  }
};

export const getFileByCountriesReportPdf = async (
  params: CountriesListParams,
): Promise<AllClaimsReportPdfResponse> => {
  try {
    const response: ApiResponse<AllClaimsReportPdfResponse> = await pdfService.get(
      ROUTES.REPORTS.ALL_COUNTRIES_PDF, params,
    );
    checkResponse(response);
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error('Bad data');
  }
};
